
import { Component, Prop, Vue } from "vue-property-decorator";
import { ISlideElement } from "@/schemas/ISlideElement";
import { apiUrl } from "@/env";

@Component({
  name: "SlideElement",
})
export default class SlideElement extends Vue {
  // Slide element
  @Prop({ type: Object, required: true })
  element!: ISlideElement;

  // Get File/Img source
  getSrc(slideId: string, fileName: string): string {
    return `${apiUrl}/slides/${slideId}/files/${fileName}`;
  }
}

// <v-img
// class="mx-auto"
// v-if="element.display && element.type === 'img'"
//   height="auto"
//
// max-width="80%"
// max-height="80vh"
//
// :src="getSrc(element.slide_id, element.content)"
//   ></v-img>
